@import '@/core/tamagoshi/scss/mixins';
@import '@/core/tamagoshi/scss/variables';

.container {
  margin-bottom: $spacingLg;
}

.logos {
  margin-top: $spacingXs;
  display: flex;
  gap: $spacingXs;
}
